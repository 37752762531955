<template>
    <div class="flex flex-col justify-between items-center w-full h-auto border-gray-100 drop-shadow-md">

        <div
            class="group/menu links w-full font-[Prata] items-center justify-center flex flex-wrap bg-evellePrimary py-2 md:py-1 rounded-sm">

            <div class="peer/menu overflow-hidden transition-[grid-template-rows] duration-500 w-full px-4 grid grid-rows-1 items-center flex-wrap md:flex-col md:grid-rows-0 "
                :class="[isNavigationOpen ? 'md:grid-rows-1' : '']" id="navbar-default">
                <div ref="navBar"
                    class=" h-full flex justify-between items-center flex-wrap md:items-start md:flex-nowrap md:gap-2 md:flex-col md:flex md:py-4">
                    <NuxtLink v-if="links" v-for="link in links" @click="isNavigationOpen = false"
                        :class="{ 'router-link-active': (link.path == currentRoute.path) }"
                        class="flex items-center mx-2 text-white sm:text-sm sm:min-h-[48px] md:hover:ml-4 hover:text-hover px-2 py-1 cursor-pointer rounded-md transition-all"
                        :to="link?.external ? link.fullPath : link.path"
                        :external="link?.external"
                        :target="link?.external? '_blank' : null">
                        {{link.name}}
                    </NuxtLink>
                </div>
            </div>

        </div>
    </div>
</template>

<script setup lang="ts">
import type { Menu } from '../../types/backendtypes'
import { useAPI } from '#imports';
const routes = useRouter()
const { currentRoute } = routes
const api = useAPI()
const isNavigationOpen = useState<boolean>('isNavigationOpen')
const navBar = ref<HTMLElement | null>(null)
const { data, error } = await useAsyncData<Menu>('/api/menus', () => $fetch('/api/menus', {
  headers: useRequestHeaders()
}))

if (error.value) {
  console.log("NAVBAR ERROR: ", error.value)
  throw createError({ statusCode: 500, statusMessage: "NavBAR Menus failed", message: "Menus is undefined", fatal: false })
}

if (!data.value) {
  throw createError({ statusCode: 500, message: "Menus is undefined", fatal: false })
}

/* Rewrite urls */
data.value.menus[0].path = '/'
const links = data.value.menus

const { pageProperties } = await api.getPage(routes.currentRoute.value.path)
const { $getPageProperty } = useNuxtApp();
const contactPage: string = $getPageProperty("contactPage", pageProperties)?.property_value as string;
const contactPageExternal: string = $getPageProperty("contactPageExternal", pageProperties)?.property_value as string;



const useContactState = useState('contactUrlState', () => ({  }))
useContactState.value = { contactPage:contactPage, contactPageExternal:contactPageExternal }
const contactRedirect = useCookie('contactRedirect')
contactRedirect.value = JSON.stringify(useContactState.value)

const menu = data.value.menus.find(x => x.path == contactPage)
  if (menu) {
    menu.fullPath = contactPageExternal
    menu.external = true;
  }

const closeMenuOutsideClick = (event: MouseEvent) => {
  if (navBar.value?.contains(event.target as Node) && !(event.target as HTMLElement).classList.contains('burgerButton')) {
    document.removeEventListener('click', closeMenuOutsideClick, true)

    // This timeout is necessary for the functionality 
    setTimeout(() => {
      isNavigationOpen.value = false
    }, 1)
  }
}

watch(isNavigationOpen, (newStatus) => {
  if (newStatus) {
    document.addEventListener('click', closeMenuOutsideClick, true)
  } else {
    document.removeEventListener('click', closeMenuOutsideClick, true)
  }
})

// Cleanup event listener on component unmount
onUnmounted(() => {
  document.removeEventListener('click', closeMenuOutsideClick, true)
})


</script>

<style lang="scss">
.router-link-active {
    @apply text-[#e3cd9a];
}
</style>